<template>
  <div
    :class="[
      'organism virtual-tour',
      organism.contentTheme,
      organism.virtualTourLayout,
      'container-' + organism.containerWidth,
    ]"
    :style="'background-color:' + organism.backgroundColor"
  >
    <div
      v-if="
        organism.virtualTourLayout === 'layout-2-col-tour-content' ||
        organism.virtualTourLayout === 'layout-2-col-content-tour'
      "
      class="mx-auto grid grid-cols-1 md:grid-cols-2 max-w-7xl min-h-[400px]"
    >
      <div
        v-if="organism.virtualTourEmbedType === 'url'"
        class="w-full max-w-full lg:max-w-2xl min-h-[400px]"
        v-html="getEmbedCodeByUrl(organism.virtualTourUrl)"
      ></div>
      <div
        class="w-full max-w-full lg:max-w-2xl min-h-[400px]"
        v-else-if="organism.virtualTourEmbedType === 'custom-embed-code'"
        v-html="organism.virtualTourCustomEmbedCode"
      ></div>
      <div
        :class="[
          organism.virtualTourLayout === 'layout-2-col-content-tour'
            ? 'md:-order-1'
            : '',
          'content w-full max-w-full px-14 sm:px-16 py-10 lg:py-20 lg:max-w-2xl',
        ]"
      >
        <h3 class="mb-3 theme-text-eyebrow">{{ organism.title2 }}</h3>
        <h2 class="mb-5 text-2xl md:text-3xl lg:text-5xl theme-text-2xl">
          {{ organism.title1 }}
        </h2>
        <div
          class="mx-auto text-lg text-gray-500 description mb-9 max-w-screen-md font-figtree leading-6"
          v-html="organism.description"
        ></div>
        <MoleculesButtons :buttons="organism" :button-colors="buttonColors" />
      </div>
    </div>
    <div
      v-else
      class="px-4 py-16 mx-auto text-center md:px-10 lg:px-20 max-w-screen-xl layout-default"
    >
      <h3 class="mb-3 theme-text-eyebrow">{{ organism.title2 }}</h3>
      <h2 class="mb-8 text-2xl md:text-3xl lg:text-5xl theme-text-6xl">
        {{ organism.title1 }}
      </h2>
      <div
        v-if="organism.virtualTourEmbedType === 'url'"
        class="w-full aspect-[3/2] mb-12"
        v-html="getEmbedCodeByUrl(organism.virtualTourUrl)"
      ></div>
      <div
        v-else-if="organism.virtualTourEmbedType === 'custom-embed-code'"
        v-html="organism.virtualTourCustomEmbedCode"
      ></div>
      <div
        class="mx-auto text-lg text-gray-500 description mb-9 max-w-screen-md font-figtree leading-6"
        v-html="organism.description"
      ></div>
      <MoleculesButtons
        class="!justify-center"
        :buttons="organism"
        :button-colors="buttonColors"
      />
    </div>
  </div>
</template>

<style>
.virtual-tour {
  .layout-default iframe {
    @apply w-full aspect-[3/2] mb-12;
  }

  &.layout-2-col-tour-content,
  &.layout-2-col-content-tour {
    iframe {
      @apply w-full h-full;
    }
    .buttons a {
      @apply leading-none py-3 text-[15px];
    }
  }

  &.light-text {
    @apply text-white;

    .description {
      @apply text-white;
    }
  }
}
</style>

<script setup>
import getVideoId from "get-video-id";

const props = defineProps({
  organism: Object,
});

const buttonColors = computed(() => {
  const buttonColorsArray = [];
  if (props.organism.button1Color) {
    buttonColorsArray.push(props.organism.button1Color);
  }
  if (props.organism.button2Color) {
    buttonColorsArray.push(props.organism.button2Color);
  }
  if (props.organism.button3Color) {
    buttonColorsArray.push(props.organism.button3Color);
  }
  return buttonColorsArray;
});

onMounted(() => {
  if (props.organism.containerWidth === "full-width") {
    addFullWidthSupport();
  }
});
</script>
